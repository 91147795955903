import React from 'react';
import whats from '../assets/whats.svg';
import telephone from '../assets/telephone.svg';
import mails from '../assets/mails.svg';
import xtwitter from '../assets/x-twitter.png';
import instagram from '../assets/instagram.png';
import facebook from '../assets/facebook.png';
import { AiOutlineYoutube } from "react-icons/ai";
import { FaTwitter, FaInstagram, FaFacebookF } from "react-icons/fa";

export default function Infos() {
  return (
    <div className='mt-[150px] w-[90%] md:w-[80%] max-w-[662px] mx-auto'>
      <div className='mb-[24px] text-[#3D5B96] font-bold text-[38px] md:text-[48px] flex justify-center items-center'>
        تواصل معنا
      </div>
      <div className='mb-[24px] text-[#3D5B96] font-bold text-[14px] md:text-[18px] text-center'>
        نرحب بتواصلكم معنا لأي إستفسارات أو اقتراحات، فنحن هنا للاستماع إليكم و تقديم الدعم اللازم
      </div>
      <div className='mt-[40px] space-y-[12px]'>
        {/* <div className='text-[#3D5B96] font-bold text-[24px]'>
          حساباتنا عبر الواتساب
        </div>
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className='bg-[#3D5B96] px-8 text-white h-[64px] flex items-center rounded-[16px] font-[IBM Plex Sans Arabic] cursor-pointer'
          >
            <img src={whats} alt='' className='mr-[24px] ml-[8px]' />
            9200315027
          </div>
        ))} */}
        {/* <div className='text-[#3D5B96] font-bold text-[24px]'>
          إتصل عبر الجوال
        </div>
        <div className='bg-[#3D5B96] text-white h-[64px] flex items-center rounded-[16px] font-[IBM Plex Sans Arabic] cursor-pointer'>
          <img src={telephone} alt='' className='mr-[24px] ml-[8px]' />
          9200343527
        </div> */}
        <div className='text-[#3D5B96] font-bold text-[24px]'>
          ارسل رسالتك عبر الايميل
        </div>
        <div className='bg-[#3D5B96] text-white h-[64px] flex items-center rounded-[16px] font-[IBM Plex Sans Arabic] cursor-pointer'>
          <img src={mails} alt='' className='mr-[24px] ml-[8px]' />
          support@hayatekabsat.com
        </div>
        <div className='text-[#3D5B96] font-bold text-[24px]'>
          تابعنا عبر الفيسبوك
        </div>
        <a href="https://www.facebook.com/profile.php?id=61565844898737">
          <div className='bg-[#3D5B96] text-white h-[64px] flex items-center rounded-[16px] font-[IBM Plex Sans Arabic] cursor-pointer'>
            <img src={facebook} alt='' className='mr-[24px] ml-[8px]' />
            hayatekabsat
          </div>
        </a>
        <div className='text-[#3D5B96] font-bold text-[24px]'>
          تابعنا عبر الإنستغرام
        </div>
        <a href="https://www.instagram.com/hayatekabsat">
          <div className='bg-[#3D5B96] text-white h-[64px] flex items-center rounded-[16px] font-[IBM Plex Sans Arabic] cursor-pointer'>
            <img src={instagram} alt='' className='mr-[24px] ml-[8px]' />
            hayatekabsat
          </div>
        </a>
        <div className='text-[#3D5B96] font-bold text-[24px]'>
          تابعنا عبر تويتر
        </div>
        <a href="https://x.com/hayatekabsat">
          <div className='bg-[#3D5B96] text-white h-[64px] flex items-center rounded-[16px] font-[IBM Plex Sans Arabic] cursor-pointer'>
            <img src={xtwitter} alt='' className='mr-[24px] ml-[8px]' />
            hayatekabsat
          </div>
        </a>
      </div>
      <div className='w-full h-[132px] mt-[40px] space-y-[12px]'>
        {/* <div className='text-[#3D5B96] font-bold text-[24px] text-center mb-[16px]'>
          حساباتنا على السوشل ميديا
        </div>
        <div className='flex gap-[16px] justify-center items-center flex-wrap md:flex-nowrap'>
          <div className='w-[60px] h-[60px] md:w-[80px] md:h-[80px] rounded-[21.83px] bg-[#e4eef7] flex items-center justify-center cursor-pointer'>
            <AiOutlineYoutube color='#3D5B96' className='text-[25px] md:text-[35px]' />
          </div>
          <div className='w-[60px] h-[60px] md:w-[80px] md:h-[80px] rounded-[21.83px] bg-[#e4eef7] flex items-center justify-center cursor-pointer'>
            <FaTwitter color='#3D5B96' className='text-[25px] md:text-[35px]' />
          </div>
          <div className='w-[60px] h-[60px] md:w-[80px] md:h-[80px] rounded-[21.83px] bg-[#e4eef7] flex items-center justify-center cursor-pointer'>
            <FaInstagram color='#3D5B96' className='text-[25px] md:text-[35px]' />
          </div>
          <div className='w-[60px] h-[60px] md:w-[80px] md:h-[80px] rounded-[21.83px] bg-[#e4eef7] flex items-center justify-center cursor-pointer'>
            <FaFacebookF color='#3D5B96' className='text-[25px] md:text-[35px]' />
          </div>
        </div> */}

      </div>
    </div>
  );
}