import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SwiperCore from 'swiper';
import Articles from '../Daynamic_Components/Articles'; 
import { articles } from '../../data/sectionData';

export default function SwiperComponent() {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const swiperRef = useRef<SwiperCore | null>(null);

  useEffect(() => {
    const updateSize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    updateSize();
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (swiperRef.current && isLargeScreen) {
      swiperRef.current.autoplay.start();
    } else if (swiperRef.current && !isLargeScreen) {
      swiperRef.current.autoplay.stop(); 
    }
  }, [isLargeScreen]);

  // Get the last 5 articles
  const lastFiveArticles = articles.slice(-5);

  return (
    <div className='mt-5 w-[85%] mx-auto mb-28'>
      <div>
        <h2 className='Bold text-[#3D5B96] text-[32px] mb-2'>
          المقالات الجديدة
        </h2>
        {/* <p className='text-[#3D5B96] text-[16px] mb-4'>
          استكشف جميع التقارير والدراسات والأدلة المعرفية المنشورة والمعدة لغرض التداول
        </p> */}
      </div>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={40}
        slidesPerView={1.5}
        loop={true}
        autoplay={isLargeScreen ? { delay: 2000, disableOnInteraction: false } : false}
        onSwiper={(swiper: SwiperCore) => (swiperRef.current = swiper)}
        breakpoints={{
          768: {
            slidesPerView: 1.5,
          },
          1024: {
            slidesPerView: 2.7,
          },
        }}
      >
        {lastFiveArticles.map((article, index) => ( 
          <SwiperSlide key={index}>
            <Articles
              img={article.img}
              title={article.title}
              description={article.description}
              id={article.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
