import React from 'react'
import logo from '../assets/hayatek-absat.svg'
// import { AiOutlineYoutube } from "react-icons/ai";
import { FaInstagram, FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';




export default function Footer() {
  return (
    <div className=' w-full bg-white mt-5 pt-4'>
      <div className='w-[85%] mx-auto flex justify-between pb-12 bg-white md:flex-row flex-col gap-y-6'>
        <div className=' mt-[-30px]'>
          <Link to={'/'}>
            <img src={logo} alt="" className='md:w-[150px] md:h-[150px] w-[90px] h-[90px] ' />
          </Link>
          {/* <p className='text-[#3D5B96] text-[16px] '>
الموقع هو منصة إلكترونية تهدف إلى تقديم محتوى غني ومتنوع يتضمن مقالات متخصصة في مختلف المجالات
</p> */}
        </div>
        <div>
          <h2 className='text-[#3D5B96] text-[20px] mb-2'>
            الوصول السريع
          </h2>
          <Link to={'/'}>
            <p className='text-[#3D5B96] text-[14px] mb-1'>
              الرئيسية
            </p></Link>
          <Link to={'/sec'}>
            <p className='text-[#3D5B96] text-[14px] mb-1'>
              أقسام المقالات
            </p>
          </Link>


        </div>
        <div>
          <h2 className='text-[#3D5B96] text-[20px] mb-2'>
            عن الموقع
          </h2>
          <Link to={'/contactInformation'}>
            <p className='text-[#3D5B96] text-[14px] mb-1'>
              تواصل معنا
            </p>
          </Link>
          <Link to={'/about'}>
            <p className='text-[#3D5B96] text-[14px] mb-1'>
              من نحن
            </p>
          </Link>
        </div>
        <div>
          <h2 className='text-[#3D5B96] text-[20px] mb-2'>
            تابعنا عبر
          </h2>
          <div className='flex gap-[16px]'>
            {/* <a href="">
              <div className=' w-[44px] h-[44px] rounded-full bg-[#EFF2F7] flex items-center justify-center'>
                <AiOutlineYoutube color='#3D5B96' size={22} />
              </div>
            </a> */}
            <a href="https://x.com/hayatekabsat">
              <div className=' w-[44px] h-[44px] rounded-full bg-[#EFF2F7] flex items-center justify-center'>
                <FaXTwitter color='#3D5B96' size={22} />
              </div>
            </a>
            <a href="https://www.instagram.com/hayatekabsat/">
              <div className=' w-[44px] h-[44px] rounded-full bg-[#EFF2F7] flex items-center justify-center'>
                <FaInstagram color='#3D5B96' size={22} />
              </div>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61565844898737">
              <div className=' w-[44px] h-[44px] rounded-full bg-[#EFF2F7] flex items-center justify-center'>
                <FaFacebookF color='#3D5B96' size={22} />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className=' w-full h-[1px] bg-[#EFF2F7] my-12 bgw'></div>
      <div className=' w-full flex items-center justify-center'>
        <h2 className='text-[#3D5B96] text-[20px] mb-[24px] '>
          © 2024 جميع الحقوق محفوظة .
        </h2>
      </div>
    </div>
  )
}


