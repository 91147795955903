import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CiSearch } from "react-icons/ci";
import img1 from '../assets/img1.svg';
import img10 from '../assets/smalscreen.svg';
import { categories } from '../data/categories';
import { articles } from '../data/sectionData';
import ArticleList from '../Components/Daynamic_Components/ArticleList';
import 'swiper/css';
import { Link } from 'react-router-dom';

export default function Sections() {
  const [selectedCategory, setSelectedCategory] = useState<string | null>('الكل');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const lastArticle = articles.length > 0 ? articles[articles.length - 1] : null;

  // Filter articles based on search query and selected category
  const filteredArticles = articles
    .filter(article =>
      article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      article.description.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter(article =>
      selectedCategory === 'الكل' || article.category === selectedCategory
    );

  if (!lastArticle) {
    return <p>No articles available</p>;
  }

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <div className='mt-[150px] w-[90%] mx-auto overflow-hidden'>
      {/* Breadcrumb Navigation */}
      <div className="flex text-blue-600 text-sm">
        <a href="#" className="text-[#3D5B96] font-bold text-[16px] hover:underline ml-[6px] ">الرئيسية</a>
        <span className='font-bold'>›</span>
        <a href="#" className="text-[#3D5B96] font-bold text-[16px] hover:underline">أقسام المقالات</a>
      </div>

      {/* Desktop view */}
      <div className='w-full md:flex hidden items-center justify-center mt-8 rounded-[12px] overflow-hidden h-[400px]'>
        <img src={lastArticle?.img} alt={lastArticle?.title} className='w-full h-auto object-cover'/>
      </div>

      {/* Mobile view */}
      <div className='w-full flex md:hidden items-center justify-center mt-8 rounded-[12px] overflow-hidden'>
        <img src={lastArticle?.img} alt={lastArticle?.title} className='w-full'/>
      </div>

      {/* Article Content */}
      <div className='mt-3 mb-7'>
        <h2 className='text-[#3D5B96] font-bold text-[24px]'>{lastArticle?.title}</h2>
        <p className='text-[#3D5B96] text-sm md:text-[16px]'>
          {lastArticle?.description}
        </p>
        <Link to={`/sec/${lastArticle?.id}`}>
          <button className='text-[#3D5B96] md:text-[16px] text-[12px] rounded-[8px] md:w-[117px] w-[100px] mt-[8px] md:px-[16px] px-[8px] py-[12px] border-solid border-[#3D5B96] font-bold border-[1px]'>
            التفاصيل
          </button>
        </Link>
      </div>

      {/* Search Input */}
      <div className='mb-4 border-[0.5px] border-solid border-[#3D5B96] bg-white rounded-full py-2 px-1 flex items-center gap-1 md:w-[373px] w-[250px] h-[48px]'>
        <CiSearch size={24} color='#3D5B96' />
        <input
          type="text"
          placeholder='ابحث عن منتجك...'
          className='outline-none text-right placeholder-[#3D5B96] mr-[12px] font-[14px] focus:placeholder-transparent'
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Categories Swiper (for mobile) */}
      <div className="p-[16px] rounded-[16px] md:hidden block w-full h-auto mb-5">
        <h3 className="text-[#3D5B96] text-[20px] font-bold mb-[16px]">أقسام المقالات</h3>
        <Swiper spaceBetween={16} slidesPerView={'auto'} freeMode={true}>
          {categories.map((category, index) => (
            <SwiperSlide key={index} style={{ width: 'auto' }}>
              <li
                className={`py-[8px] px-[16px] rounded-[8px] border-[1px] border-solid border-[#3D5B96] mb-[8px] cursor-pointer ${
                  selectedCategory === category ? 'bg-[#3D5B96] text-white' : 'text-[#3D5B96]'
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </li>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Categories List (for desktop) */}
      <div className='w-full flex gap-4'>
        <div className="bg-white p-[16px] rounded-[16px] hidden md:block shadow-md w-[216px] h-auto">
          <h3 className="text-[#3D5B96] text-[20px] font-bold mb-[16px]">أقسام المقالات</h3>
          <ul className='w-full'>
            {categories.map((category, index) => (
              <li
                key={index}
                className={`py-[8px] px-[16px] rounded-[8px] mb-[8px] cursor-pointer ${
                  selectedCategory === category ? 'bg-[#3D5B96] text-white' : 'text-[#3D5B96]'
                }`}
                onClick={() => handleCategoryClick(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        <div className="px-[10px] py-[16px] bg-white rounded-[16px] shadow-md flex-1">
          <h2 className="text-[#3D5B96] text-[20px] font-bold mb-4">المقالات</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
            {filteredArticles.map((article:any, index) => (
              <ArticleList
                key={index}
                img={article.img}
                id={article.id}
                title={article.title}
                description={article.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
