import React from 'react'
import Product from '../assets/Product.svg'
import Services from '../assets/Services.svg'
import Star from '../assets/Star.svg'
import badger from '../assets/badger.svg'
import Pov from '../assets/Pov.svg'
import Check from '../assets/Check.svg'
import Message from '../assets/Message.svg'

import { visitorsData } from '../data/visitorsData';



export default function About() {
  return (
    <div className='mt-[150px]'>
      <div className="flex items-center space-x-2 text-blue-600 text-sm">
        <a href="#" className="text-[#3D5B96] font-bold text-[16px] hover:underline ml-[6px] mr-[120px]">الرئيسية</a>
        <span className='font-bold'>›</span>
        <a href="#" className="text-[#3D5B96] font-bold text-[16px] hover:underline">من نحن</a>
      </div>
      <div className='flex flex-col-reverse md:flex-row justify-between items-center w-full'>
        <div className='w-[80%] md:w-[559px] mt-6 md:mt-[50px] mb-8 md:mb-[130px] mx-6 md:ml-[140px] md:mr-[120px]'>
          <h1 className='text-[#3D5B96] font-bold text-2xl md:text-[48px] mb-[30px] md:mb-[30px]'>
            من نحن ؟
          </h1>
          <p className='text-[#3D5B96] font-bold text-sm md:text-[18px] text-justify leading-[30px]'>
          نحن موقع متخصص بتقديم مجموعة متنوعة من المنتجات ونركز على المنتجات التي تساهم في تبسيط حياتك وتوفير وقتك وجهدك، نهدف إلى تزويدك بمعلومات شاملة عن كل منتج لمساعدتك في اتخاذ قرار الشراء الصحيح، وأيضا نوفر لك شراء المنتجات مباشرة من أمازون من خلال روابطنا التسويقية، وللتأكد من جودة المنتجات نحن نحرص على اختيار منتجات ذات جودة عالية بناءً على تقييمات المستخدمين وعدد المبيعات لضمان رضاك التام.
          </p>
        </div>
        <div className='w-full md:w-auto flex justify-center md:justify-end'>
          <img src={Product} alt='' className='max-w-full h-auto md:max-w-none' />
        </div>
      </div>

      <div className='w-full mb-[50px] md:mb-[100px]'>
        <h1 className='text-[#3D5B96] font-bold text-[38px] md:text-[48px] mb-[30px] md:mb-[24px] mt-12 md:mt-[100px] text-center'>
          قيمنا
        </h1>
        <div className='flex w-full items-center justify-center flex-col md:flex-row gap-12 md:gap-[88px] h-auto'>
          <div className='w-[50%] md:w-[260px] text-center'>
            <img src={Services} alt="" className='mx-auto mb-6 md:mb-[24px] w-[56px] h-[56px] md:w-auto md:h-auto' />
            <h3 className='text-[#3D5B96] font-bold text-lg md:text-[24px] mb-2 md:mb-[8px]'>الجودة والموثوقية</h3>
            <p className='text-[#3D5B96] font-bold text-sm md:text-[18px] text-center leading-[30px]'>
            نحرص على اختيار منتجات ذات جودة عالية وبأسعار مناسبة، باستخدام روابط أمازون التسويقية.
            </p>
          </div>
          <div className='w-[50%] md:w-[260px] text-center'>
            <img src={Star} alt="" className='mx-auto mb-6 md:mb-[24px] w-[56px] h-[56px] md:w-auto md:h-auto' />
            <h3 className='text-[#3D5B96] font-bold text-lg md:text-[24px] mb-2 md:mb-[8px]'>التطوير المستمر</h3>
            <p className='text-[#3D5B96] font-bold text-sm md:text-[18px] text-center leading-[30px]'>
            نسعى دائمًا للتطوير والتعلم من الأخطاء لتحسين تجربة المستخدمين بشكل مستمر.
            </p>
          </div>
          <div className='w-[50%] md:w-[260px] text-center'>
            <img src={badger} alt="" className='mx-auto mb-6 md:mb-[24px] w-[56px] h-[56px] md:w-auto md:h-auto' />
            <h3 className='text-[#3D5B96] font-bold text-lg md:text-[24px] mb-2 md:mb-[8px]'>الشفافية والنزاهة</h3>
            <p className='text-[#3D5B96] font-bold text-sm md:text-[18px] text-center leading-[30px]'>
            نقدم معلومات واضحة عن المنتجات تشمل مميزاتها وسلبياتها، لضمان الثقة وتجنب أي شعور بالخداع.
            </p>
          </div>
        </div>
      </div>

      <div className='w-full h-auto mb-10 md:mb-[200px]'>
        <h1 className='text-[#3D5B96] font-bold text-[38px] md:text-[48px] mb-4 md:mb-[16px] text-center'>
          مميزاتنا
        </h1>
        <div className='flex w-full items-center justify-center flex-col md:flex-row gap-8 md:gap-16'>
          <div className='w-[60%] md:w-[372px] bg-white  flex-col items-center text-center rounded-[16px] drop-shadow-lg p-6 md:p-[32px]'>
            <div className=' w-full flex items-center justify-center'>

              <img src={Pov} alt="" className='w-[40px] h-[40px] md:w-[56px] md:h-[56px] mb-6 md:mb-[24px]' />
            </div>
            <h3 className='text-[#3D5B96] font-bold text-xl md:text-[24px] mb-4 md:mb-[16px]'> التصميم وسهولة الاستخدام </h3>
            <p className='text-[#3D5B96] font-bold text-base md:text-[18px] leading-[30px]'>
            نقدم تصميما أنيقا وسهل الاستخدام، مما يسهل عليك العثور على المنتجات بكل سهولة.
            </p>
          </div>
          <div className='w-[60%] md:w-[372px] bg-white flex-col items-center text-center rounded-[16px] drop-shadow-lg p-6 md:p-[32px]'>
            <div className=' w-full flex items-cente   text-center  justify-center'>

              <img src={Check} alt="" className='w-[40px] h-[40px] md:w-[56px] md:h-[56px] mb-6 md:mb-[24px]' />
            </div>
            <h3 className='text-[#3D5B96] font-bold text-xl md:text-[24px] mb-4 md:mb-[16px]'> شمولية المعلومات </h3>
            <p className='text-[#3D5B96] font-bold text-base md:text-[18px] leading-[30px]'>
            نعتمد على جمع المعلومات من مصادر متنوعة لضمان تقديم معلومات مفيدة وشاملة.
            </p>
          </div>
          <div className='w-[60%] flex-col items-center text-center md:w-[372px] bg-white rounded-[16px] drop-shadow-lg p-6 md:p-[32px]'>
            <div className=' w-full flex items-cente   text-center  justify-center'>

              <img src={Message} alt="" className='w-[40px] h-[40px] md:w-[56px] md:h-[56px] mb-6 md:mb-[24px]' />
            </div>
            <h3 className='text-[#3D5B96] font-bold text-xl md:text-[24px] mb-4 md:mb-[16px]'> الفائدة </h3>
            <p className='text-[#3D5B96] font-bold text-base md:text-[18px] leading-[30px]'>
            نركز على المنتجات التي تساعد في تبسيط حياتك وتجعلها أكثر راحة.
            </p>
          </div>
        </div>
      </div>

      <div className='w-full  mb-[100px] bg-[#3D5B96]'>
        <div className='pt-[40px] md:pb-[128.7px] pb-[40px] '>
          <div className='text-center mb-[48px]'>
            <h1 className='text-white font-bold text-[48px] mb-[16px]'>
              إحصائيات
            </h1>
            {/* <p className='text-white font-bold text-[16px]'>
              لقد حققنا العديد من الإنجازات في وقت قصير
            </p> */}
          </div>
          <div className='flex w-full items-center justify-center gap-24 gap-y-6 flex-wrap '>
            {visitorsData.map((visitor: any, index: any) => (
              <div key={index} className='flex items-center gap-[24px] md:w-[226px] w-[200px]'>
                <div className='bg-white w-[70px] h-[74px] rounded-[20px] flex items-center justify-center'>
                  <img src={visitor.image} alt={visitor.title} />
                </div>
                <div>
                  <h2 className='text-white text-[24px]'>{visitor.howMany}</h2>
                  <p className='text-white'>{visitor.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className='w-[80%] mx-auto  mb-[69px] flex items-center justify-center gap-[72px] md:flex-row flex-col '>
  <div className=' rounded-[16px]   flex-wrap col-auto border-[#d6e1ee] border-solid border-[1px]'>
    <div className='mt-[70px] mr-[32px] ml-[32px]
    mb-[48px] flex row-auto'>
    <img src={Whats} alt="" className='w-[32px] h-[32px]'/>
  <p className='mr-[8px] flex justify-center items-center text-[#3D5B96] text-[16px]'>920031027</p>
    </div>
    <div className=' mr-[32px] ml-[32px]
    mb-[48px] flex row-auto'>
    <img src={Dm} alt="" className='mr-[-25px] mt-[-25px] mb-[-25px] ml-[-25px]'/>
  <p className='mr-[8px] flex justify-center items-center text-[#3D5B96] text-[16px]'>920031027</p>
    </div>
    <div className=' mr-[32px] ml-[32px]
    mb-[48px] flex row-auto'>
    <img src={telephone} alt="" className='w-[32px] h-[32px]'/>
  <p className='mr-[8px] flex justify-center items-center text-[#3D5B96] text-[16px]'>920031027</p>
    </div>
    <div className=' mr-[32px] ml-[32px]
    mb-[48px] flex row-auto'>
    <img src={Location} alt="" className='w-[32px] h-[32px]'/>
  <p className='mr-[8px] flex justify-center items-center text-[#3D5B96] text-[16px]'> الرياض ،
  exemple , Saudi</p>
    </div>
  </div>
  <div className='w-full flex flex-col items-center md:items-start justify-center px-4 md:px-0'>
<div className='flex flex-col items-center justify-center w-full md:text-left mb-8'>
  <h3 className='text-[#3D5B96] font-bold text-2xl md:text-[48px] mb-4 md:mb-[16px]'>
    تواصل معنا
  </h3>
  <p className='text-[#3D5B96] font-bold text-base md:text-[18px] mb-4 md:mb-[16px] text-center'>
    لديك سؤال او تعليق؟ نحن موجودين للمساعدة ارسل لنا رسالة وسنقوم بالرد خلال 24 ساعة
  </p>
</div>

<div className='w-full md:flex md:space-x-4 md:space-x-reverse'>
  <input
    type="text"
    placeholder="الاسم الكامل*"
    className="w-full md:w-[calc(50%-8px)] h-[44px] p-2 border-[#3D5B96] border-solid border-[1px] mb-4 md:mb-0 outline-none rounded-md text-right"
  />
  <input
    type="text"
    placeholder="رقم الجوال*"
    className="w-full md:w-[calc(50%-8px)] h-[44px] p-2 border-[#3D5B96] border-solid border-[1px] mb-4 md:mb-0 outline-none rounded-md text-right"
  />
</div>

<div className='w-full'>
  <input
    type="email"
    placeholder="عنوان البريد الإلكتروني*"
    className="w-full h-[44px] p-2 border-[#3D5B96] border-solid border-[1px] mb-4 mt-4 outline-none rounded-md text-right"
  />
</div>

<div className='w-full'>
  <textarea
    placeholder="شاركنا مشاكلك أو اقتراحاتك هنا"
    rows={4}
    className="w-full h-[106px] p-2 border-[#3D5B96] border-solid border-[1px] mb-4 mt-4 outline-none rounded-md text-right"
  ></textarea>
</div>

<div className='w-full'>
  <button className="w-full h-[48px] bg-[#3D5B96] mt-4 text-white py-2 rounded-md font-bold">
    إرسال
  </button>
</div>
</div>

    </div> */}
    </div>
  )
}
