import React from 'react'
import { CiSearch } from "react-icons/ci";
import herImage from '../../assets/Group 606.svg'
import { Link } from 'react-router-dom';
export default function Hero() {
  return (
    <div className='mt-[150px] px-3'>
      <div className='w-full flex flex-col items-center justify-center gap-3'>
        <h1 className='md:text-[48px] text-[32px] text-[#3D5B96] text-center'>
          موقع المقالات والتقييمات
        </h1>
        <p className='desc md:text-[26px] text-[20px] text-[#3D5B96] text-center md:w-[35%] w-full'>
          استكشف مجموعة متنوعة من المقالات واحصل على أفضل التقييمات للمنتجات
        </p>
        <div className='md:flex-row flex-col flex gap-4 items-center'>
          {/* <div className='border-[0.5px] border-solid border-[#3D5B96] rounded-[8px] py-2 px-1 flex items-center gap-1 w-[250px]'>
            <CiSearch size={24} color='#3D5B96' />
            <input type="text" placeholder='البحث' className='focus:outline-none border-none bg-transparent' />
          </div> */}
          <Link to={'sec'}>
          <div className='bg-[#3D5B96] py-2 px-[24px] rounded-[8px] hidden md:block'>
            <a href="/" className='text-white'>
              <h2>تصفح المنتجات و المقالات</h2>
            </a>
          </div>
          </Link>
         
          <Link to={'/sec'}>
          <div className='bg-[#3D5B96] py-2 px-[24px] rounded-[8px] md:hidden block w-[100%] text-center'>
            <a href="/" className='text-white'>
              <h2>تصفح المنتجات</h2>
            </a>
          </div></Link>
          
        </div>
        <img src={herImage} alt="" className='md:w-[80%] w-full mt-12 md:h-auto h-[160px] object-cover' />
      </div>
      <div className='w-full items-center justify-center'>

      </div>
    </div>
  )
}
