// data.ts

import main_image_cameras from '../assets/cameras-artical/main-image-short.webp'
import laptop_stands from '../assets/laptop-stand/main-image.webp'


import Product1 from '../Components/ProdoctComponents/Product1'
import Product2 from '../Components/ProdoctComponents/Product2'


export const articles = [

  { id:1,
    img: main_image_cameras,
    title: 'كاميرات المراقبة',
    description: 'أنواع كاميرات المراقبة مع المميزات والسلبيات وثلاث منتجات بسعر جيد',
     category: 'إلكترونيات',
     component: Product1
  },
  { id:2,
    img: laptop_stands,
    title: 'حاملات اللابتوب',
    description: 'حاملات اللابتوب وأهميتها وأسعارها مع بعض المنتجات للشراء',
     category: 'إكسسوارات اللابتوب',
     component: Product2
  },


];
