import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/hayatek-absat.svg';
import { TiDelete } from "react-icons/ti";
import { FaBars } from "react-icons/fa6";
import { Link } from 'react-router-dom';

export default function NavBar() {
    const [menu, setMenu] = useState(false);
    const menuRef = useRef<HTMLUListElement | null>(null); // Define the ref type

    // Function to handle clicks outside the menu
    const handleClickOutside = (event: MouseEvent) => {
        // Check if menuRef.current is not null and if the clicked element is outside of it
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setMenu(false);
        }
    };

    useEffect(() => {
        // Add event listener when the component is mounted
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup the event listener when the component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='fixed left-0 top-0 w-full bg-[#F3FBFF] z-50'>
            <div className='md:flex items-center mx-auto w-[85%] justify-between py-[16px] hidden'>
                <Link to={'/'}>
                    <img src={logo} alt="" className='w-[85px] h-[85px]' />
                </Link>
                <ul className='flex gap-[32px]'>
                    <Link to={'/'} className='navText text-[#3D5B96] text-[18px]'>الرئيسية</Link>
                    <Link to={'/sec'} className='navText text-[#3D5B96] text-[18px]'>أقسام المقالات</Link>
                    <Link to={'/about'} className='navText text-[#3D5B96] text-[18px]'>من نحن</Link>
                </ul>
                <div className='bg-[#3D5B96] py-[12px] px-[24px] rounded-[8px]'>
                    <Link to={'/contactInformation'} className='text-white'>
                        <h2>تواصل معنا</h2>
                    </Link>
                </div>
            </div>
            <div className='flex items-center mx-auto w-[85%] justify-between py-[16px] md:hidden'>
                {!menu ? (
                    <FaBars size={23} className='text-[#3D5B96]' onClick={() => setMenu(true)} />
                ) : (
                    <TiDelete size={30} className='text-[#3D5B96]' onClick={() => setMenu(false)} />
                )}
                <Link to={'/'}>
                    <img src={logo} alt="" className='w-[80px] h-[80px]' />
                </Link>

                <ul ref={menuRef} className={`${menu ? 'flex' : 'hidden'} gap-[32px] flex-col absolute top-20 bg-[#3D5B96] rounded-[8px] py-5 px-10 z-20`}>
                    <li onClick={()=>setMenu(false)} className='navText text-[#ffffff] text-[18px]'><Link to={'/'}>الرئيسية</Link></li>
                    <li onClick={()=>setMenu(false)} className='navText text-[#ffffff] text-[18px]'><Link to={'/sec'}>أقسام المقالات</Link></li>
                    <li onClick={()=>setMenu(false)} className='navText text-[#ffffff] text-[18px]'><Link to={'/about'}>من نحن</Link></li>
                </ul>
                <Link to={'/contactInformation'}>
                    <div className='bg-[#3D5B96] md:py-[12px] py-[8px] md:px-[24px] px-[16px] rounded-[8px]'>
                        <a href="/" className='text-white'>
                            <h2>تواصل معنا</h2>
                        </a>
                    </div>
                </Link>
            </div>
        </div>
    );
}
